export const STATUS_PENDENTE_ANALISE = 0
export const STATUS_EM_ANALISE = 1
export const STATUS_AGUARDANDO_DOCUMENTACAO = 2
export const STATUS_AGUARDANDO_PAGAMENTO = 3
export const STATUS_AGUARDANDO_REGISTRO = 90
export const STATUS_ATIVO = 100
export const STATUS_BLOQUEADO = 99
export const STATUS_EXPIRADO = 101

export const status = {
  [STATUS_PENDENTE_ANALISE]: {label: 'Análise pendente', color: '#2E2E2E'},
  [STATUS_EM_ANALISE]: {label: 'Em análise', color: '#2E2E2E'},
  [STATUS_AGUARDANDO_DOCUMENTACAO]: {label: 'Aguardando documentação', color: '#2E2E2E'},
  [STATUS_AGUARDANDO_PAGAMENTO]: {label: 'Aguardando pagamento', color: '#2338AF'},
  [STATUS_AGUARDANDO_REGISTRO]: {label: 'Aguardando registro', color: '#136F37'},
  [STATUS_ATIVO]: {label: 'Ativo', color: '#2E2E2E'},
  [STATUS_BLOQUEADO]: {label: 'Bloqueado', color: '#690000'},
  [STATUS_EXPIRADO]: {label: 'Expirado', color: '#b99400'},
}

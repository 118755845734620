<template>
  <div v-if="!prevent" class="simple-menu m-t-xs flex justify-between column full-width e-input-modern size1">
      <div class="stask-search stask-search-suporte">
        <e-row mr>
          <e-col>
            <erp-s-field label="Solicitante">
              <person-select v-model="filters.solicitante"/>
            </erp-s-field>
          </e-col>
          <!--            <e-col style="max-width: 200px">
                        <erp-s-field label="Status">
                          <status-select v-model="filters.status"/>
                        </erp-s-field>
                      </e-col>-->
          <e-col style="min-width: 150px">
            <date-input @keydown.enter.native="container.load()" label="Data (De)" v-model="filters.data1"/>
          </e-col>
          <e-col style="min-width: 150px">
            <date-input @keydown.enter.native="container.load()" label="Data (Até)" v-model="filters.data2"/>
          </e-col>
          <e-col style="min-width: 150px">
            <erp-s-field
                label="Filtro Data"
            >
              <u-select
                  hide-underline inverted-light class="no-box-shadow"
                  :options="[
                            {label: 'Cadastro', value: null},
                            {label: 'Registro', value: 'dataRegistro'},
                            {label: 'Expiração', value: 'dataExpiracao'},
                        ]"
                  v-model="filters.tipoData"/>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>

      <e-row class="m-t" mr>
        <e-col class="text-right">
          <a @click="reset" class="m-r font-12 text-negative">Remover filtros</a>
          <e-btn label="Buscar" @click="buscarFechar"/>
        </e-col>
      </e-row>
    </div>
</template>

<script>
import {UPopover, USelect} from "uloc-vue"
import {ErpInput, ErpSField, ErpSelect} from "uloc-vue-plugin-erp"
import ECol from "@/components/layout/components/Col"
import ERow from "@/components/layout/components/Row"
import filters from "@/domain/gerencia/clientes/helpers/filtersCliente"
import DateInput from "@/reuse/input/Date.vue"
import PersonSelect from "components/suporte/components/projetos/include/PersonSelect.vue";

export default {
  name: "ConsoleClienteFiltrosAdicionais",
  inject: ['container'],
  props: ['filters'],
  components: {
    // PersonSelect,
    ErpSField,
    DateInput,
    ERow,
    ECol,
    USelect,
    //MenuOptionsItem,
    PersonSelect,
    //ErpSField,
  },
  mounted() {
  },
  watch: {},
  data() {
    return {
      prevent: false
    }
  },
  beforeDestroy() {
  },
  methods: {
    activated() {
      this.$nextTick(() => {
        // this.$refs.input0.focus()
      })
    },
    buscar() {
      this.container.pesquisar()
    },
    buscarFechar() {
      this.container.pesquisar()
    },
    reset() {
      this.prevent = true
      this.container.filters = JSON.parse(JSON.stringify(filters))
      this.$nextTick(() => {
        this.prevent = false
      })
      this.container.pesquisar()
    }
  }
}
</script>

import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 3
export const listName = 'fenaju.tickets.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: false, sortable: true, ordering: 1},
    {label: 'Domínio', name: 'dominio', active: true, sortable: true, ordering: 3},
    {label: 'Ticket', name: 'ticket', active: true, sortable: true, ordering: 5},
    {label: 'CPF', name: 'cpf', active: true, sortable: true, ordering: 5},
    {label: 'Leiloeiro', name: 'leiloeiro', active: true, sortable: true, ordering: 5},
    {label: 'Token', name: 'token', active: true, sortable: true, ordering: 5},
    {label: 'Criado', name: 'createdAt', active: true, sortable: true, ordering: 5},
    {label: 'Válido', name: 'status', active: true, sortable: true, ordering: 12},
], listVersion)

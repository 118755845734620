const filters = {
    id: null,
    busca: null,
    solicitante: null,
    data1: null,
    data2: null,
    tipoData: null,
    status: [],
    active: null
}

export default filters
